.our-partner .owl-nav {
	display: none;
}

.our-partner .owl-carousel .owl-item img {
	width: unset !important;
}

.our-partner .owl-item {
	display: flex;
	justify-content: center;
}