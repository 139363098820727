.property-details-area .ant-select{
    border-bottom: 1px solid rgba(197, 197, 197, 0.5);
    width: 100%;
}
.property-details-area .contact .rld-single-input{
    margin-bottom: 15px;
}
/*
.property-details-area input{
    font-size: 11px !important;
    color: black;
}
 */
.property-details-area  .ant-empty-normal{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 50px;
    width: 100%;
}
.property-details-area  .property-news-single-card {
    margin-bottom: 30px;
    padding-bottom: 20px;
}
.property-details-area .ant-select-selector{
    padding: 0 !important;
}
.property-details-area input::placeholder{
    font-size: 13px !important;
    color: #b8b9bc;
}

.leaflet-parent {
    width: 100%;
    height: 300px;
}

.leaflet-parent .leaflet-container {
    height: 100%;
    width: 100%;
    z-index: 0;
    border-radius: 6px;
}

.property-details-area .energy-classes {
    margin-bottom: 0px !important;
}