

.search_list .single-feature .details-wrap{
    width: 100%;
}
.search_list .single-feature .details .contact-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.search_list .single-feature .details .info-list{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.search_list .empty{
    height: 450px;
    display: flex;
    justify-content: center;
    flex-flow: column;
}
.search_list .widget-sidebar-search-wrap .ant-slider .ant-slider-track{
    background-color: #FDA94F;
}
.search_list .widget-sidebar-search-wrap .ant-slider .ant-slider-handle::after{
    box-shadow: 0 0 0 2px #ffc280;
}
.search_list .single-feature .details .info-list span{
    color: #FDA94F;
    font-weight: bolder;
    font-size: 13px;
    padding-right: 5px;
}
.search_list .single-feature .details .contact-list .btn{
    height: 30px;
    line-height: 28px;
    padding: 0 15px;
}