.news-area .thumb img {
	height: 170px;
	min-width: 100%;
	cursor: pointer;
	object-fit: cover;
}

.news-area .details {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 15em;
}

.news-area .details h5 {
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
}
