.featured-area .single-feature .details .title{
    width: 100%;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.featured-area  .justify-content-center{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.featured-area  .justify-content-center .ant-empty-normal{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 300px;
}
.featured-area .single-feature .thumb{
    height: 182.14px;
}
.featured-area .single-feature .thumb img{
    height: 100%;
}
.featured-area .single-feature .details .info-list{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.featured-area .single-feature .details .info-list span{
    color: #55c1de;
    font-weight: bolder;
    font-size: 13px;
    padding-right: 5px;
}
.featured-area .single-feature .details .contact-list{
    background:#FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.featured-area .single-feature .details .contact-list .phone{
    background: #e5f7fc;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 4px;
    display: inline-block;
}
.featured-area .single-feature .details .contact-list .btn{
    height: 30px;
    line-height: 28px;
    padding: 0 15px;
}