.ant-modal-confirm-btns button{
    background: var(--main-color-one);
    color: #fff;
}
.wrapperNotif  .ant-modal-confirm-content{
    margin-left: 1px !important;
    margin-top: 15px !important;
    max-width: 100% !important;
    padding: 15px 10px;
    background-color: #F3F3F3;
}
.ant-modal-confirm-content .ant-typography {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 0px !important;
}
.ant-modal-confirm-content .rowAlert{
    display: flex;
    flex-flow: row nowrap;
}
.ant-modal-confirm-content .site-result-demo-error-icon{
    color: red;
    margin-right: 10px;
}
.ant-modal-confirm-content .rowAlert{
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 14px;
}