/* Adpated but original from https://gist.github.com/robertklep/5021124 */
.energy-class {
	position: relative;
	width: 155px;
	font-family: sans-serif;
}
.energy-class div {
	display: flex;
	align-items: center;
	position: relative;
	height: 20px;
	margin: 2px 0;
}
.energy-class div:before {
	content: "";
	position: absolute;
	top: 0;
	left: 100%;
	background: transparent;
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
}
.energy-class div:after {
	position: absolute;
	right: 0.3em;
	height: 20px;
	line-height: 20px;
	color: #fff;
	text-shadow: 0 0 2px #000;
	text-transform: uppercase;
}
.energy-class div.content{
	font-size: 13px;
	line-height:1.4em;
}
.energy-class.energy div.a {
	background: #379932;
}
.energy-class.ges div.a {
	background: #f6edfe;
}
.energy-class div.a:after {
	content: " a ";
}
.energy-class.energy div.b {
	background: #3acc31;
}
.energy-class.ges div.b {
	background: #e4c7fb;
}
.energy-class div.b:after {
	content: " b ";
}
.energy-class.energy div.c {
	background: #cdfd33;
}
.energy-class.ges div.c {
	background: #d2adf1;
}
.energy-class div.c:after {
	content: " c ";
}
.energy-class.energy div.d {
	background: #fbea49;
}
.energy-class.ges div.d {
	background: #c99aef;
}
.energy-class div.d:after {
	content: " d ";
}
.energy-class.energy div.e {
	background: #fccc2f;
}
.energy-class.ges div.e {
	background: #b77ae9;
}
.energy-class div.e:after {
	content: " e ";
}
.energy-class.energy div.f {
	background: #fb9c34;
}
.energy-class.ges div.f {
	background: #a659e9;
}
.energy-class div.f:after {
	content: " f ";
}
.energy-class.energy div.g {
	background: #fa1c1f;
}
.energy-class.ges div.g {
	background: #8835d9;
}
.energy-class div.g:after {
	content: " g ";
}
.energy-class.energy
	.energy-classes
	div:nth-child(n + 3):nth-last-child(n + 3)::after {
	color: #1a1a1a;
}
.energy-class.ges .energy-classes div:nth-last-child(1n + 4)::after {
	color: #1a1a1a;
}
.energy-class div.a {
	border-radius: 5px 0 0 5px;
}
.energy-class div.g {
	border-radius: 0 5px 5px 0;
}
.energy-class .energy-classes div {
	width: 20px !important;
}
.energy-class .energy-classes div.active {
	font-size: 1.4rem;
	height: 2.2rem !important;
	padding: 0 0.3rem;
	border: 2px solid #fff;
	box-sizing: content-box;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	border-radius: 6px !important;
}
.energy-class-title {
	display: flex;
	align-items: baseline !important;
	padding-bottom: 2.5rem;
	gap: 0.5rem;
}
.energy-info-icon:hover {
	color: gray;
	cursor: help;
}

.energy-class-details {
	display: flex !important;
	align-items: unset !important;
	
	position: absolute !important;
	background-color: white;
	padding: 2rem;
	
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
	
	border-radius: 10px;

	width:35rem!important;
	height: 32rem !important;
	/*width:  50rem !important;
	height: 44rem !important;
	*/
	z-index: 100;
}
.popup {
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
.popup span {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.popup .content {
	padding-top: 4.2rem;
	color: #1a1a1a;
}