.property-news-area .thumb {
    height: 17em;
}

.property-news-area .thumb img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.property-news-area .details {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 12em;
}

.property-news-area .details h4 {
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
}
